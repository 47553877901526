// @import "../../../scss/mixins";

a.btn.barlink.text-primary   {
  position: absolute;
  left: 6px;
  z-index: 9;
  top: 21px;
}

a.btn.barlink.text-primary   {
  left: 15px;
  width: 32px;
  height: 25px;
  padding: 0px 0px;
  top: 25px;
}

.headerSearch {
  left: 30px;
}

.ml-1.nameFont b {
  font-size: 14px;
  font-weight: 500;
  color: #202020;
}

.ml-1.nameFont p {
  font-size: 12px;
  font-weight: 400;
  color: #898989;
}

.dropdown-item {
  padding: 0.25rem 1rem !important;
}

.grayCls {
  color: #84818a;
}

.ml-1.nameFont {
  line-height: 18px;
}

.sidebar-brand.p-3.pt-4.text-left.pl-5 {
  padding: 0px !important;
  height: 72px !important;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  /* padding-left: 50px !important; */
  /* padding-left: 50px !important; */
  justify-content: center;
  background: #f9fafb;
  z-index: 9;
}



i.fas.fa-bars {
  color: #84818a;
  font-size: 18px;
}

.main-navbar {
  background-color: #fff;
  color: #fff;
  height: 72px;
  width: calc(100% - 280px);
  // margin-left: 250px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e8e8e8 !important;
  position: fixed;
  top: 0px;
  z-index: 9;
  margin: 0 0 0 auto;
  right: 0;

  &.min-sidebar {
    margin-left: 0px;
  }

  .nav-link {
    padding: 10px;
  }

  .nav-link-user {
    > img {
      width: 32px;
      height: 32px;
    }
  }
}

input.Searchbar:focus {
  outline: none;
}

.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;

  .Searchbar {
    background: #f9f9f9;
    border: none;
    padding: 10px 35px;
    border-radius: 8px;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
  }

  > i {
    position: absolute;
    top: 50%;
    color: #84818a;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-search {
      left: 10px;
    }

    &.fa-times {
      right: 10px;
    }
  }
}

.notification {
  color: #cacccf;
  font-size: 27px;
  transform: rotate(29deg);
  margin-right: 22px;
  margin-top: 16px;
}

.reddot {
  background-color: red;
  padding: 2px 5px;
  border-radius: 14px;
  color: #ffff;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: -2px;
  border: 3px solid #ffff;
  transform: rotate(333deg);
}

.min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;
}


// .main-sidebar.d-md-block span {
//   display: none;
// }
.main-sidebar.d-md-block:hover {
 
  transition-duration: 0.6s;
}
// .main-sidebar.d-md-block:hover span {
//   display: block;
// }

.min-sidebar ~ div > .main-sidebar a.col_side {
  padding: 8px;
  border-radius: 6px !important;
  margin-right: 10px !important;
  width: 41px;
  justify-content: center;

  &:hover {
    justify-content: center;
    margin-right: 11px !important;
  }
}

.min-sidebar {
  ~ div > .main-sidebar {
    width: 78px;
    min-width: 68px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }

    .nav-link span {
      display: none;
    }

    .nav-link.hoverclass {
      justify-content: center !important;
    }

    & i {
      margin-right: 0px;
    }

    & i.sidenv {
      display: block !important;
      margin-right: 0px;
    }
  }

  ~ div > main.main {
    width: calc(100% - 78px);
  }
}

.min-sidebar {
  ~ div > .main-sidebar {
    width: 78px;
    min-width: 68px;

    .sidebar-brand {
      width: 0;
      visibility: hidden;
    }

    .submenu {
      display: block;
    }

    a.col_side span {
      display: none;
    }

    .card.card-body.sides {
      background-color: transparent;
    }
  }
}
.min-sidebar ~ div > .main-sidebar i.sidenv {
  display: flex !important;
  margin: 0 !important;
}


.noti-fi{
      position: absolute;
        top: 15px;
        right: 179px;
        padding: 1px;
        background: red;
        border-radius: 81%;
        height: 6px;
        width: 6px;
}