.dropdownOverlap{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    display: none;
    z-index: 999;
  }
  .daterangeDropdown{
    z-index: 999;
  }

  .rdrDateDisplayWrapper{
    display: none;
  }
  .cld_btn button {
    width: 47%;
}
.new_dropdown {
  width: fit-content !important;
}
.dropdown_width {
  transform: translate3d(-130px, 60px, 0px) !important;
}