// @import "../../../scss/mixins";

// .main-sidebar .nav .nav-item.active > .nav-link {
//   background-color: #fff;
//   color: #707070;
//   font-size: 14px;
//   font-weight: 500;
// }
// .btn:hover {
//   color: #ffffff;
// }
// .hoverclass:hover {
//   background: #d7f5ff;
//   color: #197d9f !important;
//   font-size: 14px;
//   font-weight: 500;
// }

// .subMenuDropdown {
//   padding-left: 14px;
// }

// .main-sidebar .nav .nav-item .nav-link {
//   color: #202020 !important;
//   font-size: 16px;
//   font-weight: 300;
//   height: 40px;
//   margin: 3px 0px;
//   display: flex;
//   margin: 3px 13px;
//   border-radius: 12px;
//   align-items: center;

//   & i {
//     color: #898989;
//     font-size: 18px;
//   }
//   & i.sidenv {
//     display: none;
//   }
// }

// .main-sidebar .nav .nav-item > .nav-link:hover,
// .main-sidebar .nav .nav-item.active > .nav-link {
//   color: #705ee6 !important;
//   background: #edecf9;
//   font-size: 16px;
//   font-weight: 300;
//   display: flex;
//   height: 40px;
//   align-items: center;
//   border-radius: 12px;

//   & i {
//     color: #775da6;
//   }
// }
// .editLogo img {
//     max-width: 155px !important;
// }

// a.col_side {
//   color: #202020 !important;
//   font-size: 16px;
//   font-weight: 300;
//   height: 40px;
//   display: flex;
//   margin: 3px 14px;
//   border-radius: 12px;
//   align-items: center;
//   padding: 0px 15px;
//   justify-content: space-between;
//   width: auto;
// }
// a.col_side:hover {
//   color: #705ee6 !important;
//   background: #edecf9;
//   font-size: 16px;
//   font-weight: 300;
//   display: flex;
//   height: 40px;
//   margin: 3px 14px;
//   border-radius: 12px;
//   align-items: center;
//   padding: 0px 15px;
//   text-decoration: none;
//   & i {
//     color: #898989;
//   }
//   &.card.card-body.sides {
//     border: none;
//     visibility: hidden;
//   }
// }

// .logocls {
//     width: 100%;

//     margin-left: 10px;
// }

// // .main-sidebar:hover {
// //     width: 186px !important;
// // }


// .hoverclass {
//   &.nobg {
//     background: none !important;
//   }
// }

// .submenu {
//   display: none;
// }

// .active > .submenu {
//   display: block;
// }

// .submenu > * > .hoverclass {
//   padding-left: 25px;
// }

// .submenu.child2 > * > .hoverclass {
//   padding-left: 35px;
// }
// span.side_head {
//   font-size: 16px;
//   font-weight: 300;
//   line-height: 20px;
// }
// .card.card-body.sides {
//   // margin:6px;
//   padding: 0px 0px;
// }
// .card.card-body.sides li.nav-item.active a span {
//   color: #765aa6 !important;
//   font-weight: 300 !important;
// }
// .card.card-body.sides li a {
//   border-radius: 4px !important;
//   padding-left: 25px;
// }
// .card.card-body.sides {
//   border: none;
// }
// .card.card-body.sides li a span {
//   color: #5a5a5a !important;
//   white-space: nowrap;
//   // font-weight: 100;
//   font-weight: 400;
//   font-size: 16px;
//   max-width: 182px;
// }
// span.side_icon {
//   position: relative;
//   right: -10px;
//   top: 3px;
//   color: #898989;
// }
// // .col_side i {
// //     padding: 20px;
// //     display: block;
// //     font-size: 100px;
// //     transition: all 0.4s ease;
// //   }

// //   .col_side :active i {
// //     transform: rotateZ(-90deg);
// //   }
// .col_side div i.material-icons.mr-2 {
//   color: #765aa6;
//   font-size: 18px;
// }
// .collapsed div i.material-icons.mr-2 {
//   color: #898989 !important;
//   font-size: 18px;
// }
// a.col_side.collapsess i.material-icons.mr-2 {
//   color: #765aa6 !important;
// }
// a.col_side.collapsess.collapsed i.material-icons.mr-2 {
//   color: #898989 !important;
//   font-size: 18px;
// }


.main-sidebar {
  left: 0;
  width: 280px;
  height: 100vh;
  padding: 8px;
  position: fixed;
  overflow: auto;
  top: 72px;
  z-index: 9;
  background-repeat: no-repeat;
  background-size: 100%;
}

//////////##### sidebar close css ///////////#####
.active-sidebar .sidebar_text {
  font-size: 7px;
  display: flex;
  line-height: 9px;
  flex-wrap: wrap;
  justify-content: center;
}

.active-sidebar .pl-\[30px\] {
  padding-left: 0px;
}

.active-sidebar .space-y-2 li a {
  width: fit-content;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: center;
}

.active-sidebar .main-sidebar {
  padding: 8px;
  text-align: center;
}

.active-sidebar .space-y-2 li button a {
  width: fit-content;
  flex-wrap: wrap;
  justify-content: center;
}

.active-sidebar .space-y-2 li button span {
  width: fit-content;
  flex-wrap: wrap;
  display: flex !important;
  justify-content: center;
}

.active-sidebar .space-y-2 li button span.crm {
  flex-direction: column;
  width: 100%;
}

.active-sidebar span.text-sm {
  flex-wrap: wrap;
  line-height: 10px;
  gap: 4px;
}

.active-sidebar .undefined {
  padding: 0px !important;
}

.active-sidebar .space-y-2 li button svg.h-4.w-4.transition-all.duration-500.text-\[\#7E8B99\] {
  display: none;
}

.active-sidebar span.text-sm .active {
  flex-wrap: wrap;
  line-height: 10px;
  gap: 0px;
  justify-content: center;
}

.active-sidebar .space-y-2 li span {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


@keyframes scroll-transition {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-transition {
  animation: scroll-transition 0.3s ease-out forwards;
}

span.sidebar_text {
    display: flex;
    gap: 10px;
    align-items: center;
}